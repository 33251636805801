import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.edicionActivada && !_vm.vistaSeguimiento && !_vm.cursoArchivado)?_c('editar-comentario',{attrs:{"idComentario":_vm.esRespuesta ? _vm.comentario.comentario : _vm.comentario._id,"contenidoActual":_vm.comentario.contenido,"idActividad":_vm.comentario.cursoActividad,"idClase":_vm.idClase,"puedeCancelar":true,"idRespuestaAEditar":_vm.esRespuesta ? _vm.comentario._id : null},on:{"cancelar":function($event){_vm.edicionActivada = false},"comentarioGuardado":_vm.comentarioEditado}}):_c(VCard,{class:{
      'padding-puntos': _vm.esRespuesta && !_vm.comentario.editado,
    },attrs:{"outlined":"","loading":_vm.loading,"color":_vm.esRespuesta ? 'grey lighten-3' : null}},[_c(VCardTitle,{class:{
        'py-1': _vm.esRespuesta,
      }},[_c(VListItem,[_c(VListItemAvatar,[_c(VAvatar,{attrs:{"size":"50"}},[(!_vm.comentario.usuario.avatar)?_c(VIcon,{attrs:{"size":"230"}},[_vm._v("mdi-account")]):_c('img',{attrs:{"src":(_vm.imageURL + "/avatar/" + (_vm.comentario.usuario.avatar)),"alt":"UserImage"}})],1)],1),(
            _vm.comentario.equipo &&
            !_vm.esRespuesta &&
            (_vm.esComentarioEquipoUsuario || _vm.permisoEscritura) &&
            !_vm.cursoArchivado
          )?_c(VListItemContent,{attrs:{"three-line":""}},[_c(VListItemTitle,[(_vm.esComentarioDeUsuario || _vm.esComentarioEquipoUsuario)?_c('span',[_c(VBadge,{attrs:{"color":"success","dot":"","inline":""}},[_c('span',[_vm._v(_vm._s(_vm.comentario.equipo.nombre))])])],1):_c('span',[_vm._v(_vm._s(_vm.comentario.equipo.nombre))])]),_c(VListItemSubtitle,{staticStyle:{"max-width":"40%"}},[_c('span',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm.nombresIntegrantesEquipo)+" ")])]),_c(VListItemSubtitle,[_c('span',[_vm._v(" "+_vm._s(_vm.obtenerFecha(_vm.comentario.fecha))+" (hace "+_vm._s(_vm.timeAgo)+") ")])])],1):_c(VListItemContent,[_c(VListItemTitle,[(_vm.esComentarioDeUsuario || _vm.esComentarioEquipoUsuario)?_c('span',[_c(VBadge,{attrs:{"color":"success","dot":"","inline":""}},[_c('span',[_vm._v(_vm._s(_vm.comentario.usuario.nombreCompleto))])])],1):_c('span',[_vm._v(_vm._s(_vm.comentario.usuario.nombreCompleto))])]),_c(VListItemSubtitle,[_c('span',[_vm._v(" "+_vm._s(_vm.obtenerFecha(_vm.comentario.fecha))+" (hace "+_vm._s(_vm.timeAgo)+") ")])])],1),(_vm.idActividad)?_c(VListItemAction,[_c('div',{staticClass:"d-flex"},[(
                !_vm.esRespuesta && _vm.permisoEscritura && !_vm.esComentarioDeUsuario && !_vm.cursoArchivado
              )?_c('cal-directa-item',{staticClass:"cal-directa-item",class:{
                'tiene-calificacion': _vm.calificacionesAlumno.length,
                'es-equipo': _vm.comentario.equipo,
              },attrs:{"permisoEscritura":_vm.permisoEscritura,"idActividad":_vm.idActividad,"idCurso":_vm.idCurso,"instrumento":_vm.instrumento,"tipoInstrumento":_vm.tipoInstrumento,"idAlumno":_vm.comentario.usuario._id,"alumnos":_vm.alumnos,"calificaciones":_vm.calificacionesAlumno,"esComentario":true,"equipos":_vm.equipos,"idEquipo":_vm.comentario.equipo ? _vm.comentario.equipo._id : null},on:{"calificacionAgregada":_vm.calificacionAgregada,"calificacionModificada":_vm.calificacionModificada}}):_vm._e(),(
                _vm.esComentarioDeUsuario &&
                !_vm.comentario.editado &&
                !_vm.vistaSeguimiento &&
                !_vm.cursoArchivado
              )?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":"","text":""},on:{"click":function($event){_vm.edicionActivada = true}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,false,4110391353)},[_c('span',[_vm._v("Editar")])]):_vm._e(),(
                (_vm.esComentarioDeUsuario || _vm.esComentarioEquipoUsuario) &&
                !_vm.permisoEscritura &&
                _vm.comentario.puntos &&
                !_vm.esRespuesta
              )?_c(VChip,{staticStyle:{"margin-top":"-3px","margin-right":"5px"},attrs:{"label":""}},[_c('span',[_vm._v(_vm._s(_vm.comentario.puntos)+" Puntos")])]):_vm._e(),(_vm.esRespuesta)?_c('puntos-respuesta',{staticStyle:{"margin-top":"-3px","margin-right":"5px"},attrs:{"idRespuesta":_vm.comentario._id,"idUsuario":_vm.comentario.usuario._id,"idCurso":_vm.idCurso,"puntos":_vm.comentario.puntos,"permisoEscritura":_vm.permisoEscritura,"esRespuestaDeUsuario":_vm.esComentarioDeUsuario || _vm.esComentarioEquipoUsuario}}):_vm._e(),_c(VBtn,{attrs:{"small":"","outlined":_vm.opinionActual !== 'like',"color":_vm.coloresOpinion.like,"disabled":_vm.loadingOpinion || _vm.vistaSeguimiento || _vm.cursoArchivado},on:{"click":function($event){return _vm.agregarOpinion('like')}}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-thumb-up")]),_c('span',[_vm._v(_vm._s(_vm.comentario.likes.length))])],1),_c(VBtn,{staticClass:"ml-1",attrs:{"small":"","outlined":_vm.opinionActual !== 'dislike',"color":_vm.coloresOpinion.dislike,"disabled":_vm.loadingOpinion || _vm.vistaSeguimiento || _vm.cursoArchivado},on:{"click":function($event){return _vm.agregarOpinion('dislike')}}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-thumb-down")]),_c('span',[_vm._v(_vm._s(_vm.comentario.dislikes.length))])],1)],1)]):_c(VListItemAction,[_c('div',{staticClass:"d-flex"},[(
                _vm.esComentarioDeUsuario &&
                !_vm.comentario.editado &&
                !_vm.vistaSeguimiento &&
                !_vm.cursoArchivado
              )?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":"","text":""},on:{"click":function($event){_vm.edicionActivada = true}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,false,4110391353)},[_c('span',[_vm._v("Editar")])]):_vm._e(),_c(VBtn,{attrs:{"small":"","outlined":_vm.opinionActual !== 'like',"color":_vm.coloresOpinion.like,"disabled":_vm.loadingOpinion || _vm.vistaSeguimiento || _vm.cursoArchivado},on:{"click":function($event){return _vm.agregarOpinion('like')}}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-thumb-up")]),_c('span',[_vm._v(_vm._s(_vm.comentario.likes.length))])],1),_c(VBtn,{staticClass:"ml-1",attrs:{"small":"","outlined":_vm.opinionActual !== 'dislike',"color":_vm.coloresOpinion.dislike,"disabled":_vm.loadingOpinion || _vm.vistaSeguimiento || _vm.cursoArchivado},on:{"click":function($event){return _vm.agregarOpinion('dislike')}}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-thumb-down")]),_c('span',[_vm._v(_vm._s(_vm.comentario.dislikes.length))])],1)],1)])],1)],1),_c(VCardText,{class:{ 'py-1': _vm.esRespuesta }},[_c('span',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(_vm.comentario.contenido))]),_c('div',[(_vm.comentario.editado)?_c(VChip,{staticClass:"mr-2",attrs:{"color":"grey","x-small":"","dark":""}},[_c(VIcon,{staticClass:"mr-1",attrs:{"x-small":""}},[_vm._v("mdi-pencil")]),_c('span',[_vm._v("Editado")])],1):_vm._e(),(
            (_vm.esExtemporaneo && _vm.esComentarioDeUsuario) ||
            (_vm.esExtemporaneo && _vm.permisoEscritura)
          )?_c(VChip,{attrs:{"color":"warning darken-1","x-small":"","dark":""}},[_c(VIcon,{staticClass:"mr-1",attrs:{"x-small":""}},[_vm._v("mdi-clock-time-eleven")]),_c('span',[_vm._v("Entrega extemporánea")])],1):_vm._e()],1),(!_vm.esRespuesta)?_c('div',{staticClass:"mt-2"},[(_vm.respuestas.length)?_c('div',[(_vm.showRespuestas)?_c(VBtn,{attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.showRespuestas = false}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-chevron-down")]),_c('span',[_vm._v("Ocultar respuestas")])],1):_c(VBtn,{attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.showRespuestas = true}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-chevron-right")]),_c('span',[_vm._v("Ver "+_vm._s(_vm.respuestas.length)+" respuestas")])],1)],1):_vm._e(),(_vm.showRespuestas)?_c('div',{staticClass:"pb-3"},_vm._l((_vm.respuestas),function(respuesta){return _c('comentario',{key:respuesta._id,staticClass:"mt-3",attrs:{"esRespuesta":true,"comentario":respuesta,"idCurso":_vm.idCurso,"idActividad":_vm.idActividad,"idClase":_vm.idClase,"permisoEscritura":_vm.permisoEscritura,"cursoArchivado":_vm.cursoArchivado}})}),1):_vm._e()]):_vm._e(),(!_vm.esRespuesta && !_vm.vistaSeguimiento && !_vm.cursoArchivado)?_c('div',{staticClass:"mt-3 d-flex"},[_c(VMenu,{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"secondary","small":"","outlined":""}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-message")]),_c('span',[_vm._v("Responder")])],1)]}}],null,false,3177101299),model:{value:(_vm.showCrearRespuesta),callback:function ($$v) {_vm.showCrearRespuesta=$$v},expression:"showCrearRespuesta"}},[_c('div',[_c('agregar-comentario',{attrs:{"idActividad":_vm.idActividad,"idClase":_vm.idClase,"idComentario":_vm.comentario._id},on:{"respuestaGuardada":_vm.respuestaGuardada}})],1)])],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }