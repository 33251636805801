<template>
  <v-dialog v-model="mostrar" :max-width="1000" persistent>
    <v-card :loading="loading">
      <v-toolbar color="primary" dark flat dense class="text-h6">
        <v-toolbar-title>Vincular video</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark icon @click="cerrarForm()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <br />
      <v-container>
        <v-row>
          <v-col md="6" sm="12">
            <v-container>
              <selector-areas
                :areas="areas"
                :menu="false"                  
                v-model="areaSeleccionada"
                @change="limpiarPagination(), getSubareas(areaSeleccionada)"
              />
            </v-container>
          </v-col>
          <v-col md="6" sm="12">
            <v-container>
              <div class="d-flex">
                <selector-areas
                  :areas="subareas"
                  :menu="false"                  
                  v-model="subareaSeleccionada"
                  :etiqueta="'Subárea'"
                  @change="limpiarPagination(), getVideos()"
                />              
              </div>
            </v-container>
          </v-col>            
        </v-row>
    
        <v-card-text v-if="!videos.length && !loading">No se han subido videos.</v-card-text >
          
        <template v-else-if="loading">
          <loading-video/>
        </template>
        <template v-else-if="videos.length>0">
          <v-container fluid >
            <v-col md="12" sm="12">
              <tablaVideos
                :loading="loading"
                :datos="videos"
                :pagination="pagination"
                :page="pagination.page"
                :itemsPerPage="10"
                :totalItems="pagination.itemsLength"
                :headers="headers"
                hideSearch
                @update-page="updatePageTable"
                @pagina-change="paginaCambio"  
              >
                <template #item-video="{ value }">
                  <div @click="vincularVideo(value._id)" style="cursor:pointer">
                    {{ value.nombre }}
                  </div>
                </template>
                <template #item-usuario="{ value }" >
                <h5 style="text-align: center"> 
                    {{ value }}
                </h5>
                </template>
                <template #item-tiempo="{ value }" >
                <h5 style="text-align: center"> 
                    {{ value }} minutos
                </h5>
                </template>
              </tablaVideos>
            </v-col>
          </v-container>
        </template>

      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapGetters } from "vuex";
import { agregarVideoClaseService } from "../modulos/clases/clases.service";

import tablaVideos from "../../widgets/datatable.vue";
import selectorAreas from "../../widgets/selectorAreas.vue";
import loadingVideo from "../../videos/loadingVideos.vue"
import { getAreasService, getSubareasService, getVideosService } from "../../videos/videos.service";

export default {
  props:{
    mostrar: Boolean,
    idCurso: String,
    idClase: String,
    tipoCurso: String,
  },

  components: {
    "selector-areas": selectorAreas,
    loadingVideo,
    tablaVideos
  },
  computed: {
    ...mapGetters(["sessionToken", "app", "role"]),
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: "",
          value: "numero",
          sortable: false,
          filterable: false,
          width: "30px",
        },
       
        {
          text: "Nombre",
          value: "video",
          sortable: false,
          filterable: false,
        },
        {
          text: "Usuario",
          value: "usuario",
          sortable: false,
          filterable: false,
           align:"center"
        },
        {
          text: "Tiempo",
          value: "tiempo",
          sortable: false,
          filterable: false,
          width: "200px",
          align:"center"
       
        },
        {
          text: "Tipo",
          value: "tipo",
          sortable: false,
          filterable: false,
          width: "50px",
          align:"center"

        }

       
      ],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        pageStart: 0,
        pageStop: 0,
        pageCount: 0,
        itemsLength: 0,
      },  
      areas: [],
      areaSeleccionada: "",
      abrirEditorArea:false,
      editarArea:false,
      areaParaModificar:null,
      videos: [],
      subareas: [],
      subareaSeleccionada: "",
      abrirEditorSubarea:false,
      subareaParaModificar:null,
      abrirEditorVideo:false,
    };
  },
  watch: {
    sessionToken(value) {
      if (value) this.getAreas();
    },
  },
  created() {
    if (this.sessionToken) this.getAreas();
  },
  methods: {
    async getAreas() {
      this.loading = true;
      try {
        const serverResponse = await getAreasService();
        this.loading = false;

        this.$validateResponse(serverResponse);
        
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else if (serverResponse.areas.length) {
          this.areas = serverResponse.areas;
          this.areaSeleccionada = serverResponse.areas[0]._id;
          this.getSubareas(this.areaSeleccionada);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async getSubareas(areaSeleccionada) {
      this.loading = true;
      try {
        const serverResponse = await getSubareasService(areaSeleccionada);
        this.loading = false;
        this.$validateResponse(serverResponse);

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          if(serverResponse.subareas.length>0){
            this.subareas = serverResponse.subareas
            this.subareaSeleccionada = this.subareas[0]._id;
            this.getVideos();
          }
          else {
            this.subareas = []
            this.videos = []
          } 
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async getVideos() {
      this.loading = true;
      try {        
        const serverResponse = await getVideosService(
                                      this.areaSeleccionada, 
                                      this.subareaSeleccionada,
                                      this.pagination.itemsPerPage,
                                      this.pagination.page
                                    );
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.videos= []; 
          let numero = 1;

          for (const video of serverResponse.videos) {
            let item = { 
              numero: numero++, 
              tiempo:video.time,
              tipo:video.type,
              usuario:video.usuario,
              video:{
                nombre:video.nombre,
                area:video.area,
                subarea:video.subarea,
                _id:video._id
              }
            }
            this.videos.push(item);
          }
          this.pagination.itemsLength = serverResponse.total;
          this.pagination.pageStop = serverResponse.pageStop;
          this.pagination.pageStart = serverResponse.pageStart;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async vincularVideo(idVideo) {
      try {
        this.loading = true;

        const data = {idVideo, area: this.areaSeleccionada, subArea: this.subareaSeleccionada}
        const ids = {idCurso: this.idCurso, idClase: this.idClase};
        const serverResponse = await agregarVideoClaseService(data, this.tipoCurso, ids);
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
          this.$emit("cancelar");
        } else {
          this.$emit("videoAgregado", serverResponse.video.video);
          this.$emit("cancelar");
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    updatePageTable(value) {
      this.pagination.pageCount += 1;
      this.pagination.page = value.page;
      this.getVideos();
    },

    paginaCambio(pagina) {
      
      this.pagination.page = pagina;
      this.getVideos();
    },
    limpiarPagination() {
      this.pagination.page = 1;
      this.pagination.pageStart = 0;
      this.pagination.pageStop = 0;
      this.pagination.pageCount = 1;
      this.pagination.itemsLength = 0;
      
    },
    cerrarForm() {
      this.$emit("cancelar");
    },
  },
};
</script>

<style scoped>
.formularioClass {
  padding: 15px;
  padding-bottom: 0;
}
</style>