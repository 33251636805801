<template>
  <v-container>
   <v-skeleton-loader v-if="!escuelaTienePermiso" type="list-item-two-line@5"></v-skeleton-loader>
    <v-card v-else rounded="lg">
      <v-toolbar dense color="secondary" dark flat class="text-h6">
        <v-btn icon @click="regresarCurso()"
          ><v-icon>mdi-arrow-left</v-icon></v-btn
        >
        <v-spacer></v-spacer> {{ nombreModulo }} <v-spacer></v-spacer>
        <claseMenuComponent
          v-if="permisoEscritura && !vistaSeguimiento"
          :clase="clase"
          :idClase="idClase"
          :idCurso="idCurso"
          :idModulo="idModulo"
          :tipoCurso="tipoCurso"
          :disabled="loading"
          @claseModificada="claseModificada"
          @videoAgregado="videoAgregado"
          @eliminarClase="regresarCurso"
        />
      </v-toolbar>

      <claseLoadingComponent v-if="loading" />

      <contenidoClaseComponent
        v-else
        :clase="clase"
        :tipoCurso="tipoCurso"
        :idCurso="idCurso"
        :idClase="idClase"
        :tipoModulo="tipoModulo"
        :prevClass="prevClass"
        :nextClass="nextClass"
        :permisoEscritura="permisoEscritura"
      />
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import claseMenuComponent from "./claseMenu.vue";
import claseLoadingComponent from "./claseLoading.vue";
import contenidoClaseComponent from "./contenidoClase.vue";
import { obtenerClaseService } from "../modulos/clases/clases.service";
import { obtenerClaseProfesorService } from "../modulos/modulosProfesorCampus.service";
import { permisoMenuEscuela } from '../../../helpers/utilerias';

export default {
  name: "claseView",
  components: {
    claseMenuComponent,
    claseLoadingComponent,
    contenidoClaseComponent,
  },
  computed: {
    ...mapGetters(["sessionToken", "app", "menu", "vistaSeguimiento"]),
  },
  watch: {
    sessionToken() {
      if (!permisoMenuEscuela("Cursos")) this.$router.push("/dashboard");
      else { 
        this.escuelaTienePermiso=true; 
        this.cargarClase();
        }
    },
    "$route.params"() {
        if (!permisoMenuEscuela("Cursos")) this.$router.push("/dashboard");
        else{
          this.escuelaTienePermiso=true;
          this.cargarClase();
          }
    },
  },
  created() {
    this.tipoCurso = this.$route.params.tipoCurso;
    this.idCurso = this.$route.params.idCurso;
    this.tipoModulo = this.$route.params.tipoModulo;
    if (this.sessionToken){
      if (!permisoMenuEscuela("Cursos")) this.$router.push("/dashboard");
      else {
        this.escuelaTienePermiso=true;
        this.cargarClase(); 
        }
    }
  },
  data() {
    return {
      loading: false,
      idCurso: null,
      idModulo: null,
      tipoModulo:null,
      idClase: null,
      nombreModulo: "Cargando clase...",
      tipoCurso: "campus",
      clase: {},
      prevClass: null,
      nextClass: null,
      permisoEscritura: false,
      escuelaTienePermiso:false
    };
  },
  methods: {
    async cargarClase() {
      this.idClase = this.$route.params.idClase; 
      try {
        this.loading = true;
        let serverResponse = null;
        if (this.tipoModulo == 'moduloProfesor' && this.app == 'campus')
          serverResponse = await obtenerClaseProfesorService(this.tipoCurso, this.idCurso, this.idClase);
        else 
          serverResponse = await obtenerClaseService(this.tipoCurso, this.idCurso, this.idClase);
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.clase = serverResponse.clase;
          this.nombreModulo = serverResponse.nombreModulo;
          this.idModulo = serverResponse.idModulo;
          this.prevClass = serverResponse.previous;
          this.nextClass = serverResponse.next;
          this.permisoEscritura = serverResponse.permisoEscritura;
          if (this.tipoCurso === "campus"){
            if (serverResponse.seguimiento == false) this.$store.dispatch("setSesionCursoAlumno",serverResponse.sesionAlumno);
            if (serverResponse.seguimiento == true) this.$store.dispatch("setVistaSeguimiento",serverResponse.seguimiento);
          }
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    claseModificada(clase) {
      this.clase.nombre = clase.nombre;
    },
    videoAgregado(video) {
      this.clase.idVideo = video;
    },
    regresarCurso() {
      this.$router.push(`/curso/${this.tipoCurso}/${this.idCurso}/tipo/${this.tipoModulo}/${this.idModulo}`)
    },
    cambioClase(clase){
      this.idClase = clase;
      this.cargarClase();
    },
  },
};
</script>
