import {
  getServerRequest,
  putServerRequest,
  patchServerRequest,
} from "../../../services/serverRequest.service";
import { store } from "@/store/store";
const { httpURL } = store.getters;

export const obtenerRespuestasComentario = async ({
  idComentario,
  idActividad,
  idCurso,
}) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };

  const url = `${httpURL}/curso/campus/${idCurso}/actividad/${idActividad}/comentario/${idComentario}/respuestas`;
  return await getServerRequest(url, config);
};

export const obtenerRespuestasComentarioClase = async ({
  idComentario,
  idClase,
  idCurso,
}) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };

  const url = `${httpURL}/curso/campus/${idCurso}/clase/${idClase}/comentario/${idComentario}/respuestas`;
  return await getServerRequest(url, config);
};

export const agregarRespuestaComentario = async ({
  idComentario,
  idActividad,
  idClase,
  idCurso,
  data,
}) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };

  const tipoRespuesta = idActividad ? "actividad" : "clase";
  const url = `${httpURL}/curso/campus/${idCurso}/${tipoRespuesta}/${
    idActividad || idClase
  }/comentario/${idComentario}/respuesta`;
  return await putServerRequest(url, data, config);
};

export const agregarOpinionARespuesta = async ({
  idRespuesta,
  idComentario,
  idCurso,
  idActividad,
  idClase,
  tipoOpinion,
}) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };

  const url = `${httpURL}/curso/campus/${idCurso}/${
    idActividad ? "actividad" : "clase"
  }/${
    idActividad || idClase
  }/comentario/${idComentario}/respuesta/${idRespuesta}/opinion/${tipoOpinion}`;
  return await putServerRequest(url, {}, config);
};

export const editarRespuesta = async ({
  idRespuesta,
  idComentario,
  idCurso,
  idActividad,
  idClase,
  respuesta,
}) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };

  const tipoRespuesta = idActividad ? "actividad" : "clase";
  const url = `${httpURL}/curso/campus/${idCurso}/${tipoRespuesta}/${
    idActividad || idClase
  }/comentario/${idComentario}/respuesta/${idRespuesta}/editar`;
  return await patchServerRequest(url, respuesta, config);
};

export const modificarPuntosRespuesta = async ({
  idRespuesta,
  idCurso,
  puntos,
}) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };

  const url = `${httpURL}/curso/campus/${idCurso}/respuesta/${idRespuesta}/puntos`;
  return await patchServerRequest(url, { puntos }, config);
};
