import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c(VMenu,{attrs:{"offset-x":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":"","disabled":_vm.disabled}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{on:{"click":function($event){_vm.agregarVideo = true}}},[_c(VListItemTitle,[_vm._v("Vincular video")]),_c(VListItemIcon,[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-link")])],1)],1),_c(VDivider,{staticClass:"mx-3"}),_c(VListItem,{on:{"click":function($event){return _vm.habilitarEdicion()}}},[_c(VListItemTitle,[_vm._v("Modificar clase")]),_c(VListItemIcon,[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1),_c(VDivider,{staticClass:"mx-3"}),_c(VListItem,{on:{"click":function($event){_vm.eliminarClase = true}}},[_c(VListItemTitle,[_c('font',{attrs:{"color":"#D32F2F"}},[_vm._v(" Eliminar clase ")])],1),_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"red darken-2","small":""}},[_vm._v("mdi-trash-can-outline")])],1)],1)],1)],1),(_vm.agregarClase)?_c('agregarClaseComponent',{attrs:{"mostrar":_vm.agregarClase,"idCurso":_vm.idCurso,"idClase":_vm.idClase,"tipoCurso":_vm.tipoCurso,"editarClase":_vm.editarClase},on:{"cancelar":function($event){(_vm.agregarClase = false), (_vm.editarClase = null)},"claseModificada":_vm.claseModificada}}):_vm._e(),(_vm.agregarVideo)?_c('agregarVideoClaseComponent',{attrs:{"mostrar":_vm.agregarVideo,"idCurso":_vm.idCurso,"idClase":_vm.idClase,"tipoCurso":_vm.tipoCurso},on:{"cancelar":function($event){_vm.agregarVideo = false},"videoAgregado":_vm.videoAgregado}}):_vm._e(),(_vm.eliminarClase)?_c('eliminarClaseComponent',{attrs:{"mostrar":_vm.eliminarClase,"idCurso":_vm.idCurso,"clase":_vm.clase,"idModulo":_vm.idModulo,"tipoCurso":_vm.tipoCurso},on:{"cancelar":function($event){_vm.eliminarClase = false},"eliminarClase":_vm.eliminar}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }