import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCardTitle,[_c(VBtn,{attrs:{"icon":"","disabled":_vm.prevClass ? false : true,"color":"primary"},on:{"click":function($event){return _vm.changeClass(_vm.prevClass)}}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1),_c(VSpacer),_vm._v(" "+_vm._s(_vm.clase.nombre)+" "),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","disabled":_vm.nextClass ? false : true,"color":"primary"},on:{"click":function($event){return _vm.changeClass(_vm.nextClass)}}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1),_c(VDivider,{staticClass:"mx-3"}),_c(VContainer,[(!_vm.clase.idVideo)?_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VIcon,[_vm._v("mdi-video-box-off")]),_vm._v(" No se ha subido un video ")],1)],1):_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('video-player',{ref:"videoPlayer",staticClass:"video-player-box",attrs:{"options":_vm.playerOptions,"playsinline":true},on:{"play":function($event){return _vm.onPlayerPlay($event)}}})],1),(_vm.app != 'empresa')?_c(VCol,{attrs:{"cols":"12"}},[_c(VToolbar,{attrs:{"dense":"","flat":""}},[_vm._v(" "+_vm._s(_vm.clase.reproducciones)+" reproducciones. "),_c(VSpacer),_c('likesChip',{attrs:{"likes":_vm.clase.likes,"rounded":true,"labelColor":"primary"}}),_c('dislikesChip',{attrs:{"dislikes":_vm.clase.likes,"rounded":true,"labelColor":"primary"}})],1)],1):_vm._e()],1)],1),_c(VDivider,{staticClass:"mx-3"}),_c(VContainer,[_c(VToolbar,{attrs:{"dense":"","flat":""}},[(!_vm.clase.descripcion && !_vm.edicionHabilitada)?_c('span',[_c('i',[_vm._v("No se ha capturado descripción")])]):_vm._e(),_c(VSpacer),(_vm.permisoEscritura && !_vm.vistaSeguimiento)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('button',_vm._g({staticStyle:{"float":"right"},on:{"click":function($event){return _vm.habilitarEdicion()}}},on),[_c(VIcon,{staticStyle:{"margin-right":"5px"},attrs:{"medium":""}},[_vm._v("mdi-square-edit-outline")])],1)]}}],null,false,410987489)},[_c('span',[_vm._v("Modificar descripción")])]):_vm._e()],1),(_vm.edicionHabilitada)?_c('div',[_c('editor-texto',{ref:"editorTexto",attrs:{"texto":_vm.clase.descripcion,"app":"app","contenido":_vm.tipoCurso == 'campus' ? 'cursoCampus' : 'cursoPlantilla',"idContenido":_vm.idCurso}}),_c('div',{staticStyle:{"text-align":"right"}},[_c(VSpacer),_c(VBtn,{staticClass:"margen",attrs:{"color":"primary","outlined":"","small":"","disabled":_vm.loading},on:{"click":function($event){_vm.edicionHabilitada = false}}},[_vm._v(" Cancelar ")]),_c(VBtn,{staticClass:"margen",attrs:{"color":"primary","dark":"","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.editarDescripcionClase()}}},[_vm._v(" Guardar ")])],1)],1):_c('div',{domProps:{"innerHTML":_vm._s(_vm.clase.descripcion)}})],1),_c(VDivider,{staticClass:"mx-3"}),((_vm.app == 'campus' || _vm.vistaSeguimiento) && _vm.tipoModulo != 'moduloProfesor' && _vm.permisoEscritura)?_c(VContainer,[_c('comentarios-clase',{attrs:{"idCurso":_vm.clase.cursoCampus,"idClase":_vm.clase._id}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }