import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":1000,"persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"color":"primary","dark":"","flat":"","dense":""}},[_c(VToolbarTitle,[_vm._v("Vincular video")]),_c(VSpacer),_c(VToolbarItems,[_c(VBtn,{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.cerrarForm()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1),_c('br'),_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"md":"6","sm":"12"}},[_c(VContainer,[_c('selector-areas',{attrs:{"areas":_vm.areas,"menu":false},on:{"change":function($event){_vm.limpiarPagination(), _vm.getSubareas(_vm.areaSeleccionada)}},model:{value:(_vm.areaSeleccionada),callback:function ($$v) {_vm.areaSeleccionada=$$v},expression:"areaSeleccionada"}})],1)],1),_c(VCol,{attrs:{"md":"6","sm":"12"}},[_c(VContainer,[_c('div',{staticClass:"d-flex"},[_c('selector-areas',{attrs:{"areas":_vm.subareas,"menu":false,"etiqueta":'Subárea'},on:{"change":function($event){_vm.limpiarPagination(), _vm.getVideos()}},model:{value:(_vm.subareaSeleccionada),callback:function ($$v) {_vm.subareaSeleccionada=$$v},expression:"subareaSeleccionada"}})],1)])],1)],1),(!_vm.videos.length && !_vm.loading)?_c(VCardText,[_vm._v("No se han subido videos.")]):(_vm.loading)?[_c('loading-video')]:(_vm.videos.length>0)?[_c(VContainer,{attrs:{"fluid":""}},[_c(VCol,{attrs:{"md":"12","sm":"12"}},[_c('tablaVideos',{attrs:{"loading":_vm.loading,"datos":_vm.videos,"pagination":_vm.pagination,"page":_vm.pagination.page,"itemsPerPage":10,"totalItems":_vm.pagination.itemsLength,"headers":_vm.headers,"hideSearch":""},on:{"update-page":_vm.updatePageTable,"pagina-change":_vm.paginaCambio},scopedSlots:_vm._u([{key:"item-video",fn:function(ref){
var value = ref.value;
return [_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.vincularVideo(value._id)}}},[_vm._v(" "+_vm._s(value.nombre)+" ")])]}},{key:"item-usuario",fn:function(ref){
var value = ref.value;
return [_c('h5',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item-tiempo",fn:function(ref){
var value = ref.value;
return [_c('h5',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(value)+" minutos ")])]}}])})],1)],1)]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }