<template>
  <div>
    <v-card-title>
      <v-btn
        icon
        :disabled="prevClass ? false : true"
        @click="changeClass(prevClass)"
        color="primary"
        ><v-icon>mdi-chevron-left</v-icon></v-btn
      >
      <v-spacer></v-spacer> {{ clase.nombre }} <v-spacer></v-spacer>
      <v-btn
        icon
        :disabled="nextClass ? false : true"
        color="primary"
        @click="changeClass(nextClass)"
        ><v-icon>mdi-chevron-right</v-icon></v-btn
      >
    </v-card-title>
    <v-divider class="mx-3"></v-divider>
    <v-container>
      <v-row v-if="!clase.idVideo">
        <v-col cols="12">
          <v-icon>mdi-video-box-off</v-icon>
          No se ha subido un video
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <video-player
            class="video-player-box"
            ref="videoPlayer"
            :options="playerOptions"
            :playsinline="true"
            @play="onPlayerPlay($event)"
          />
        </v-col>
        <v-col cols="12" v-if="app != 'empresa'">
          <v-toolbar dense flat>
            {{ clase.reproducciones }} reproducciones.
            <v-spacer></v-spacer>
            <likesChip
              :likes="clase.likes"
              :rounded="true"
              labelColor="primary"
            />
            <dislikesChip
              :dislikes="clase.likes"
              :rounded="true"
              labelColor="primary"
            />
          </v-toolbar>
        </v-col>
      </v-row>
    </v-container>
    <v-divider class="mx-3"></v-divider>
    <v-container>
      <v-toolbar dense flat>
        <span v-if="!clase.descripcion && !edicionHabilitada"
          ><i>No se ha capturado descripción</i></span
        >
        <v-spacer></v-spacer>
        <v-tooltip v-if="permisoEscritura && !vistaSeguimiento" top>
          <template v-slot:activator="{ on }">
            <button style="float: right" v-on="on" @click="habilitarEdicion()">
              <v-icon medium style="margin-right: 5px"
                >mdi-square-edit-outline</v-icon
              >
            </button>
          </template>
          <span>Modificar descripción</span>
        </v-tooltip>
      </v-toolbar>
      <div v-if="edicionHabilitada">
        <editor-texto
          ref="editorTexto"
          :texto="clase.descripcion"
          app="app"
          :contenido="tipoCurso == 'campus' ? 'cursoCampus' : 'cursoPlantilla'"
          :idContenido="idCurso"
        ></editor-texto>
        <div style="text-align: right">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="margen"
            outlined
            small
            :disabled="loading"
            @click="edicionHabilitada = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            class="margen"
            dark
            small
            :loading="loading"
            @click="editarDescripcionClase()"
          >
            Guardar
          </v-btn>
        </div>
      </div>
      <div v-else v-html="clase.descripcion"></div>
    </v-container>
    <v-divider class="mx-3"></v-divider>
    <v-container v-if="(app == 'campus' || vistaSeguimiento) && tipoModulo != 'moduloProfesor' && permisoEscritura">
      <comentarios-clase
        :idCurso="clase.cursoCampus"
        :idClase="clase._id"
      ></comentarios-clase>
    </v-container>
  </div>
</template>

<script>
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import { mapGetters } from "vuex";
import likesChip from "../../widgets/chips/likesChip.vue";
import dislikesChip from "../../widgets/chips/dislikesChip.vue";
import editorTexto from "../../contenidos/editorTexto.vue";
import comentarioComponent from "../../widgets/comentarios/comentario.vue";
import Comentarios from "../../comentarios/Comentarios.vue";
import { modificarDescripcionClaseService } from "../modulos/clases/clases.service";

export default {
  name: "contenidoClase",
  props: {
    clase: Object,
    tipoCurso: String,
    tipoModulo: String,
    idCurso: String,
    idClase: String,
    prevClass: String || null,
    nextClass: String || null,
    permisoEscritura: Boolean,
  },
  components: {
    videoPlayer,
    likesChip,
    dislikesChip,
    "comentarios-clase": Comentarios,
    "editor-texto": editorTexto,
  },
  mounted() {
    if (this.clase.idVideo)
      this.playerOptions.sources[0].src = `${this.videoURL}/${this.clase.idVideo.route}`;
  },
  computed: {
    ...mapGetters(["app", "videoURL", "vistaSeguimiento"]),
  },
  watch: {
    "clase.idVideo.route": function (route) {
      this.playerOptions.sources[0].src = `${this.videoURL}/${route}`;
    },
  },
  data() {
    return {
      loading: false,
      playerOptions: {
        // width: "200",
        responsive: true,
        muted: false,
        language: "en",
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm",
            // src: "",
          },
        ],
        poster: "/img/imagen.png",
      },
      edicionHabilitada: false,
      hayClaseSiguiente: true,
      hayClasePrevia: true,
    };
  },
  methods: {
    onPlayerPlay(event) {
      //TODO registrar la reproducción en el servidor
    },
    changeClass(idClase) {
      this.$router.push(
        `/curso/${this.tipoCurso}/${this.idCurso}/${this.tipoModulo}/clase/${idClase}`
      );
    },
    habilitarEdicion() {
      this.edicionHabilitada = true;
    },
    async editarDescripcionClase() {
      try {
        this.loading = true;
        const data = {
          descripcion: this.$refs.editorTexto.obtenerTexto(),
        };

        const serverResponse = await modificarDescripcionClaseService(
          data,
          this.tipoCurso,
          this.idCurso,
          this.idClase
        );
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.clase.descripcion = serverResponse.clase.descripcion;
          this.edicionHabilitada = false;
        }
      } catch (error) {
        this.$appErrorMessage();
        this.loading = false;
      }
    },
    clasePosterior() {
      this.$emit("cambioClase", this.claseSiguiente);
    },
    claseAnterior() {
      this.$emit("cambioClase", this.clasePrevia);
    },
  },
};
</script>

<style>
.margen {
  margin: 5px !important;
}
</style>
