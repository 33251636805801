import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardText,[_c('div',{staticClass:"d-flex"},[_c(VAvatar,{attrs:{"size":"50"}},[(!_vm.userAvatar)?_c(VIcon,{attrs:{"size":"230"}},[_vm._v("mdi-account")]):_c('img',{attrs:{"src":(_vm.imageURL + "/avatar/" + _vm.userAvatar),"alt":"UserImage"}})],1),_c(VTextarea,{staticClass:"ml-2",attrs:{"label":_vm.labelTextBox,"outlined":"","rows":"3","maxLength":"600","counter":"600"},model:{value:(_vm.comentario.contenido),callback:function ($$v) {_vm.$set(_vm.comentario, "contenido", $$v)},expression:"comentario.contenido"}})],1),_c('div',{staticClass:"d-flex"},[_c(VSpacer),(_vm.puedeCancelar)?_c(VBtn,{staticClass:"mr-1",attrs:{"outlined":"","small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_c('span',[_vm._v("Cancelar")])]):_vm._e(),_c(VBtn,{attrs:{"color":"primary","small":"","loading":_vm.loading,"disabled":!_vm.comentario.contenido},on:{"click":function($event){return _vm.guardarComentario()}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-content-save")]),_c('span',[_vm._v("Guardar")])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }