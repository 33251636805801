import { render, staticRenderFns } from "./agregarVideoClase.vue?vue&type=template&id=0ade2356&scoped=true"
import script from "./agregarVideoClase.vue?vue&type=script&lang=js"
export * from "./agregarVideoClase.vue?vue&type=script&lang=js"
import style0 from "./agregarVideoClase.vue?vue&type=style&index=0&id=0ade2356&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ade2356",
  null
  
)

export default component.exports