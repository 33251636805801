import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"outlined":"","shaped":""}},[_c(VToolbar,{attrs:{"flat":""}},[_c(VListItem,[_c(VListItemAvatar,[_c(VImg,{attrs:{"src":"https://cdn.vuetifyjs.com/images/lists/1.jpg"}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Nombre de usuario")]),_c(VListItemSubtitle,[_vm._v("Fecha del comentario")])],1)],1),_c(VSpacer),_c('likes-chip',{attrs:{"btn":true,"active":true,"iconColor":"primary"}}),_c('dislikes-chip',{attrs:{"btn":true}})],1),_c(VDivider),_c(VCardText,[_vm._v(" Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus risus magnis gravida, ultrices eleifend sodales odio tortor praesent venenatis massa nulla vivamus, inceptos montes sed ut diam egestas sollicitudin class turpis ac. Convallis curabitur vivamus nulla blandit fermentum habitant platea molestie donec, rutrum dapibus quis purus tellus nostra morbi faucibus malesuada egestas, luctus dis curae cursus lectus cras accumsan id. Consequat ornare scelerisque commodo fringilla sociis id est, congue elementum placerat conubia nunc quisque duis dictum, netus lobortis praesent aliquam interdum phasellus. ")]),_c(VDivider,{staticClass:"mx-3"}),_c(VCardText,[_c(VBtn,{attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.verRespuestas = !_vm.verRespuestas}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-comment-text-multiple-outline")]),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v("4 respuestas")])],1),_c('respuestasTimeline',{directives:[{name:"show",rawName:"v-show",value:(_vm.verRespuestas),expression:"verRespuestas"}]})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }