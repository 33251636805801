import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[(!_vm.escuelaTienePermiso)?_c(VSkeletonLoader,{attrs:{"type":"list-item-two-line@5"}}):_c(VCard,{attrs:{"rounded":"lg"}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":"secondary","dark":"","flat":""}},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.regresarCurso()}}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1),_c(VSpacer),_vm._v(" "+_vm._s(_vm.nombreModulo)+" "),_c(VSpacer),(_vm.permisoEscritura && !_vm.vistaSeguimiento)?_c('claseMenuComponent',{attrs:{"clase":_vm.clase,"idClase":_vm.idClase,"idCurso":_vm.idCurso,"idModulo":_vm.idModulo,"tipoCurso":_vm.tipoCurso,"disabled":_vm.loading},on:{"claseModificada":_vm.claseModificada,"videoAgregado":_vm.videoAgregado,"eliminarClase":_vm.regresarCurso}}):_vm._e()],1),(_vm.loading)?_c('claseLoadingComponent'):_c('contenidoClaseComponent',{attrs:{"clase":_vm.clase,"tipoCurso":_vm.tipoCurso,"idCurso":_vm.idCurso,"idClase":_vm.idClase,"tipoModulo":_vm.tipoModulo,"prevClass":_vm.prevClass,"nextClass":_vm.nextClass,"permisoEscritura":_vm.permisoEscritura}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }