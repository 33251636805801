<template>
  <v-card elevation="1">
    <v-toolbar flat dense>
      <v-list-item style="padding-left: 0px;">
        <v-list-item-content>
          <v-list-item-title>Nombre de usuario</v-list-item-title>
          <v-list-item-subtitle>Fecha del comentario</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-spacer></v-spacer>
      <likes-chip />
      <dislikes-chip />
    </v-toolbar>
    <v-card-text
      >Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola
      imperdiet nec ut, sed euismod convenire principes at. Est et nobis iisque
      percipit, an vim zril disputando voluptatibus, vix an salutandi
      sententiae.</v-card-text
    >
  </v-card>
</template>

<script>
import likesChip from "../chips/likesChip.vue";
import dislikesChip from "../chips/dislikesChip.vue";

export default {
  name: "respuesta",
  props: {
    comentario: Object,
  },
  components: { likesChip, dislikesChip },
  data() {
    return {
      verRespuestas: false,
    };
  },
  methods: {},
};
</script>
