<template>
  <v-timeline dense align-top>
    <v-timeline-item
      v-for="n in 4"
      :key="n"
    >
      <template v-slot:icon>
        <v-avatar size="40">
          <img src="https://i.pravatar.cc/64">
        </v-avatar>
      </template>
      <respuestaComponent />
    </v-timeline-item>
    <v-timeline-item
    >
      <template v-slot:icon>
        <v-avatar size="40">
          <img src="https://i.pravatar.cc/64">
        </v-avatar>
      </template>
      <v-textarea
          flat
          label="Respuesta..."
          outlined
          rows="3"
        />
        <v-btn color="primary" small>Responder</v-btn>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import respuestaComponent from './respuesta.vue';

export default {
  name: "respuestasTimeline",
  props: {
    comentario: Object,
  },
  components: { respuestaComponent },
  data() {
    return {
        verRespuestas: false
    };
  },
  methods: {},
};
</script>
