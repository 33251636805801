<template>
  <v-card outlined shaped>
    <v-toolbar flat>
      <v-list-item>
        <v-list-item-avatar>
          <v-img src="https://cdn.vuetifyjs.com/images/lists/1.jpg"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Nombre de usuario</v-list-item-title>
          <v-list-item-subtitle>Fecha del comentario</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-spacer></v-spacer>
      <likes-chip :btn="true" :active="true" iconColor="primary" />
      <dislikes-chip :btn="true" /> </v-toolbar
    ><v-divider></v-divider>
    <v-card-text>
      Lorem ipsum dolor sit amet consectetur adipiscing elit phasellus risus
      magnis gravida, ultrices eleifend sodales odio tortor praesent venenatis
      massa nulla vivamus, inceptos montes sed ut diam egestas sollicitudin
      class turpis ac. Convallis curabitur vivamus nulla blandit fermentum
      habitant platea molestie donec, rutrum dapibus quis purus tellus nostra
      morbi faucibus malesuada egestas, luctus dis curae cursus lectus cras
      accumsan id. Consequat ornare scelerisque commodo fringilla sociis id est,
      congue elementum placerat conubia nunc quisque duis dictum, netus lobortis
      praesent aliquam interdum phasellus.
    </v-card-text>
    <v-divider class="mx-3"></v-divider>
    <v-card-text>
      <v-btn small text color="primary" @click="verRespuestas = !verRespuestas"
        ><v-icon small>mdi-comment-text-multiple-outline</v-icon>
        <!-- El texto de este botón cambia´ra de acuerdo al número de respuestas -->
        <span style="margin-left: 5px">4 respuestas</span>
      </v-btn>
      <respuestasTimeline v-show="verRespuestas" />
    </v-card-text>
  </v-card>
</template>

<script>
import likesChip from "../chips/likesChip.vue";
import dislikesChip from "../chips/dislikesChip.vue";
import respuestasTimeline from "./respuestasTimeline.vue";

export default {
  name: "comentario",
  props: {
    comentario: Object,
  },
  components: { likesChip, dislikesChip, respuestasTimeline },
  data() {
    return {
      verRespuestas: false,
    };
  },
  methods: {},
};
</script>
