<template>
  <div>
    <v-card outlined :loading="loading">
      <v-toolbar dense flat dark color="primary">
        <v-toolbar-title>
          <span>Comentarios</span>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <div class="mb-3">
          <listado-comentarios
            :comentarios="comentarios"
            :entregaActividad="entregaActividad"
            :idActividad="idActividad"
            :idClase="idClase"
            :idCurso="idCurso"
            :instrumento="instrumento"
            :tipoInstrumento="tipoInstrumento"
            :cargarCalificacionesAlumnos="permisoEscritura"
            :equipos="equipos"
            :permisoEscritura="permisoEscritura || vistaSeguimiento"
            :cursoArchivado="cursoArchivado"
          ></listado-comentarios>
        </div>
        <agregar-comentario
          v-if="!restringirComentario && !restringirPorTiempo && puedeComentar && !vistaSeguimiento && !cursoArchivado"
          :idActividad="idActividad"
          :idClase="idClase"
          @comentarioGuardado="comentarioGuardado"
        ></agregar-comentario>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ListadoComentarios from "./ListadoComentarios.vue";
import {
  obtenerComentariosActividad,
  obtenerComentariosClase,
} from "./comentarios.service";

import { mapGetters } from "vuex";

export default {
  props: {
    idActividad: {
      type: String,
    },

    idClase: {
      type: String,
    },

    descripcion: {
      type: String,
    },

    entregaActividad: {
      type: Object,
    },

    idCurso: {
      type: String,
    },

    instrumento: {
      type: String,
    },

    tipoInstrumento: {
      type: String,
    },

    puedeComentar: {
      type: Boolean,
      default: true,
    },

    equipos: {
      type: Array,
    },

    permisoEscritura: {
      type: Boolean,
      default: false,
    },
    cursoArchivado: { type: Boolean, default: false },
  },

  components: {
    "agregar-comentario": () => import("./AgregarComentario.vue"),
    "listado-comentarios": ListadoComentarios,
  },

  data() {
    return {
      loading: false,
      comentarios: [],
    };
  },

  computed: {
    ...mapGetters(["userId", "vistaSeguimiento"]),

    restringirComentario() {
      const esActividadSinDescripcion = this.idActividad && !this.descripcion;
      const comentarioActual = this.comentarios.find(
        (c) => c.usuario._id == this.userId
      );
      return (
        (!!comentarioActual && !this.permisoEscritura) ||
        esActividadSinDescripcion
      );
    },

    restringirPorTiempo() {
      if (
        !this.entregaActividad ||
        this.entregaActividad.abierta ||
        this.entregaActividad.entregaExt ||
        this.permisoEscritura
      )
        return false;

      const fechaActual = new Date().getTime();
      const fechaInicio = new Date(this.entregaActividad.inicio).getTime();
      const fechaFin = new Date(this.entregaActividad.fin).getTime();

      return fechaActual < fechaInicio || fechaActual > fechaFin;
    },
  },

  mounted() {
    this.cargarComentarios();
  },

  methods: {
    async cargarComentarios() {
      this.loading = true;

      try {
        const serverResponse = this.idActividad
          ? await obtenerComentariosActividad({
              idActividad: this.idActividad,
              idCurso: this.idCurso,
            })
          : await obtenerComentariosClase({
              idClase: this.idClase,
              idCurso: this.idCurso,
            });
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) this.$notificarError(serverResponse.mensaje);
        else {
          this.comentarios = serverResponse.comentarios;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    comentarioGuardado(comentario) {
      this.comentarios.push(comentario);
    },
  },
};
</script>
