<template>
  <div class="text-center">
    <v-menu offset-x left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon small v-bind="attrs" v-on="on" :disabled="disabled">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item @click="agregarVideo = true">
          <v-list-item-title>Vincular video</v-list-item-title>
          <v-list-item-icon><v-icon small>mdi-link</v-icon></v-list-item-icon>
        </v-list-item>
        <v-divider class="mx-3"></v-divider>
        <v-list-item @click="habilitarEdicion()">
          <v-list-item-title>Modificar clase</v-list-item-title>
          <v-list-item-icon><v-icon small>mdi-pencil</v-icon></v-list-item-icon>
        </v-list-item>
        <v-divider class="mx-3"></v-divider>
        <v-list-item @click="eliminarClase = true">
          <v-list-item-title>
            <font color="#D32F2F"> Eliminar clase </font>
          </v-list-item-title>
          <v-list-item-icon
            ><v-icon color="red darken-2" small
              >mdi-trash-can-outline</v-icon
            ></v-list-item-icon
          >
        </v-list-item>
      </v-list>
    </v-menu>

    <agregarClaseComponent
      v-if="agregarClase"
      :mostrar="agregarClase"
      :idCurso="idCurso"
      :idClase="idClase"
      :tipoCurso="tipoCurso"
      :editarClase="editarClase"
      @cancelar="(agregarClase = false), (editarClase = null)"
      @claseModificada="claseModificada"
    />

    <agregarVideoClaseComponent
      v-if="agregarVideo"
      :mostrar="agregarVideo"
      :idCurso="idCurso"
      :idClase="idClase"
      :tipoCurso="tipoCurso"
      @cancelar="agregarVideo = false"
      @videoAgregado="videoAgregado"
    />

    <eliminarClaseComponent
      v-if="eliminarClase"
      :mostrar="eliminarClase"
      :idCurso="idCurso"
      :clase="clase"
      :idModulo="idModulo"
      :tipoCurso="tipoCurso"
      @cancelar="eliminarClase = false"
      @eliminarClase="eliminar"
    />
  </div>
</template>

<script>
import agregarClaseComponent from "../modulos/clases/agregarClase.vue";
import agregarVideoClaseComponent from "./agregarVideoClase.vue";
import eliminarClaseComponent from "./eliminarClase.vue"

export default {
  name: "claseMenu",
  components: { agregarClaseComponent, agregarVideoClaseComponent, eliminarClaseComponent },
  props: {
    clase: Object,
    idClase: String,
    idCurso: String,
    idModulo: String,
    tipoCurso: String,
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: true,
      agregarClase: false,
      editarClase: null,
      agregarVideo: null,
      eliminarClase: false,
    };
  },
  methods: {
    habilitarEdicion() {
      this.editarClase = { ...this.clase };
      this.agregarClase = true;
    },
    claseModificada(clase) {
      this.$emit("claseModificada", clase);
    },
    videoAgregado(video) {
      this.$emit("videoAgregado", video);
    },
    eliminar(idClase){
      this.$emit("eliminarClase", idClase);
    },
  },
};
</script>
